import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable, catchError, throwError } from 'rxjs';
import { UserService } from '../services/user/user.service';

@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(
    private userService: UserService,
    private router: Router,
    private messageService: MessageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.userService.getToken();
    
    // if (token) {
    //   req = req.clone({
    //     setHeaders: {
    //       authorization: `${token}`,
    //     },
    //   });
    // }

    return next.handle(req).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 0 || err.message === 'Failed to fetch') {
            // Network error or server is not available
            this.messageService.add({
              severity: 'error',
              summary: 'Error de Conexión',
              detail: 'El servidor no está disponible. Por favor, inténtelo más tarde.'
            });
          } else if (err.status === 401) {
            // Specific handling for unauthorized errors         
            console.error('Unauthorized request:', err);
            // You might trigger a re-authentication flow or redirect the user here
          } 

          // Check for GraphQL errors
          if (err.error && err.error.errors) {
            const graphqlErrors = err.error.errors;
            for (const error of graphqlErrors) {
              if (error.message === "No autorizado") {
                this.userService.logout();
                break;
              } else {
                console.error('GraphQL error:', error.message);
              }
            }
          } else {
            // Handle non-HTTP errors
            console.error('An error occurred:', err);
          }
        }

        // Re-throw the error to propagate it further
        return throwError(() => err); 
      })
    );
  }
}
