import { Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class TokenGuard {
  constructor(private userService: UserService, private router: Router) {}

  canActivate: CanActivateFn = () => {
    const token = this.userService.getToken();
    if (token && !this.userService.isTokenExpired(token)) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  };
}
