import { Component } from '@angular/core';
import { ProvinceService } from '../../../services/provinces/province.service';
import { MenuItem, MessageService } from 'primeng/api';
import { ComunService } from '../../../services/comun/comun.service';
import { Iprovince, Province } from '../province.model';
import { Icountry } from '../../country/country.model';
import { CountryService } from 'src/app/services/country/country.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-province',
  templateUrl: './new-province.component.html',
  styleUrls: ['./new-province.component.scss'],
  providers: [MessageService]
})
export class NewProvinceComponent {
 // variables
 countries: Icountry[] | undefined = [];
 selectedCountry:Icountry | undefined = undefined;
 province = new Province();
 inputName:string | undefined = undefined;
 loading = false;

 constructor( 
              private provinceService: ProvinceService,
              private countryService: CountryService,
              private messageService: MessageService,
              private comunservice: ComunService,
              private activatedRoute:ActivatedRoute
              ) {

    
    // CHECK EDIT
    const {id} = this.activatedRoute.snapshot.queryParams;
    
    if (id != undefined) {
      this.loadEdit(id);
    }else {
      this.setMenu([{ label: "LISTADO PROVINCIAS", routerLink: ["/provinces"] },{ label: "CREAR PROVINCIA" }]);
      this.loadCountries()
    }
    
  
  };


 btnSave():void {
  
   this.province.data!.errors = [];
   this.province.setName= this.inputName;
   this.province.setCountry= this.selectedCountry?.id;

   if (this.province.data?.errors.length !=0) {
    this.showErrors();
    return;
    
   }

   this.province.data.id == undefined ? this.save(): this.update();
 }

 save(): void {
  const data: Iprovince = {
    name: this.province.data?.name,
    countryId: this.province.data?.countryId
  }

   this.loading= true;
   this.provinceService.setProvince(data).subscribe({

     next: ((resp:any ) => {
       this.messageService.add({
         key:      'msg',
         severity: 'success',
         summary:  'MDG Info',
         detail:   resp.createProvince
       })
       this.loading= false;

     }),
     error: (err: any) => {
      
       this.messageService.add({
         key:      'msg',
         severity: 'error',
         icon: 'error',
         summary:  'MDG Info',
         detail:   err.message || 'Error de servidor'
       })
       this.loading= false;

     }
   }) 
 }

 update(): void {
  const data: Iprovince = {
    id: this.province.data?.id,
    name: this.province.data?.name,
    countryId: this.province.data?.countryId
  }
  this.loading=true;

  this.provinceService.updateProvince(data).subscribe({
    next: (resp) => {

      this.loading= false;
      this.messageService.add({
         key:      'msg',
         severity: 'success',
         summary:  'MDG Info',
         detail:   'Provincia Actualizada'
       });

       this.loading= false;
    },
    error: (err) => {
      this.loading=false;
      this.messageService.add({
        key:      'msg',
        severity: 'error',
        summary:  'MDG Info',
        detail:   err.message
      })
      this.loading= false;
      
    }
  })
 }

 loadCountries(): Promise<boolean> {

  return new Promise ((resolve, reject)=> {
    this.countryService.getCountries(10000,0).subscribe({
      next:(data) => {
        this.countries = data.countries;
        resolve (true);
      },
      error:(err) => {
        console.log(err);
        reject (false);
      }
    })
  });
 
 }

 setMenu(menu: MenuItem[]) {
  this.comunservice.setTitleBar(menu);

 }

 btnNew() {
  this.selectedCountry= undefined;
  this.province.data!.id= undefined;
  this.province.data!.name= undefined;
  this.province.data!.countryId= undefined;
  this.inputName='';

  this.setMenu([{ label: "LISTADO PROVINCIAS", routerLink: ["/provinces"] },{ label: "CREAR PROVINCIA" }]);
 }

 async loadEdit(provinceId:string) {
  this.setMenu([{ label: "LISTADO PROVINCIAS", routerLink: ["/provinces"] },{ label: "ACTUALIZAR PROVINCIA" }]);
  await this.loadCountries();
  this.provinceService.getProvinceById(provinceId).subscribe({
    next: (data) => {
      this.selectedCountry = {id: data.provinces[0].country.id, name: data.provinces[0].country.name };
      this.inputName= data.provinces[0].name;
      this.province.data!.id= data.provinces[0].id;
    },
    error: (err) => {
      console.log(err);
    }
  })
    
 }

 showErrors() {
  // CREAR MI LISTA DE ERRORES
  let fieldError = "";

  this.province.data?.errors!.forEach(error => {
      fieldError += error.message + '\<br>';
      
  });

  
    setTimeout(() => {
      this.messageService.add({
        key: "errorForm",
        severity: "error",
        summary: "MDG Audiovisuales",
        detail: fieldError
       
      });
      fieldError='';
    }, 0);

}

}
