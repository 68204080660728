import { Component } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-activate-user',
  
  templateUrl: './activate-user.component.html',
  styleUrl: './activate-user.component.scss',
  providers:[MessageService]
})
export class ActivateUserComponent {
  public estadoActualizacion: boolean | undefined= undefined;

  constructor( private userService:UserService, 
               private messageServise:MessageService,
               private activatedRoute:ActivatedRoute,
               private router: Router  ){
    
    let email:string | undefined = undefined;
    this.activatedRoute.queryParams.subscribe({
      next: (parametro:any) => {
        if (parametro.user) { email= parametro.user}
      },
      error: ()=> {
        this.messageServise.add({
          key:'msg',
          severity:'error',
          icon:'error',
          summary:'MDG INFO',
          detail:'Se ha producido un error al obtener el parametro'
        });
      }
    })
     
    this.activate(email || '');
  }

  activate(email:string):void {
    this.userService.activateUser(email).subscribe({
      next:() => {
        this.estadoActualizacion=true;
        this.messageServise.add({
          key:'msg',
          severity:'success',
          icon:'success',
          summary:'MDG INFO',
          detail:'Activacion realizada'
        });

        setTimeout(() => {
            this.router.navigate(['/login'])
        }, 3000);
      },
      error:(err)=> {
        this.estadoActualizacion=false;

        this.messageServise.add({
          key:'msg',
          severity:'error',
          icon:'error',
          summary:'MDG INFO',
          detail: err.message || 'Error de Servidor'
        });
      }
    })
  }
}
