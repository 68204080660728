import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { SharedService } from '../../services/shared/shared.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss'],
})
export class MenubarComponent implements OnInit {
  items: MenuItem[] = [];
  userMenu: MenuItem[] = [];
  private userEmail = localStorage.getItem('email');
  permiso: any | null = null;

  constructor(private userService: UserService, private router: Router, private shareService: SharedService) {}

  ngOnInit(): void {
    // lógica para mostrar el menú adecuado según el control de acceso
    this.items = [
      // Administrador
      {
        visible: this.userService.getPermiso(undefined, '') ?? false,
        label: 'Admimistrador',
        icon: 'pi pi-lock',
        items: [
          {
            label: 'Crear Usuario',
            icon: 'pi pi-file-plus',
            iconStyle: { 'color': 'green' },
            command: (event) => {
              this.router.navigate(['/user/new']);
            },
          },
          {
            label: 'lista de usuarios',
            icon: 'pi pi-fw pi-align-left',
            command: (event) => {
              this.router.navigate(['/user/list']);
            },
          },
          {
            label: 'email activar',
            icon: 'pi pi-fw pi-align-left',
            command: (event) => {
              this.router.navigate(['/user/activar']);
            },
          },
        ],
      },
      // clientes
      {
        visible: this.userService.getPermiso('customer', 'read') ?? false,
        label: 'Clientes',
        icon: 'pi pi-users',
        items: [
          {
            visible: this.userService.getPermiso('customer', 'read') ?? false,
            label: 'Listado Clientes',
            icon: 'pi pi-fw pi-align-left',
            command: (event) => {
              this.router.navigate([{ outlets: { primary: 'customers', sidebar: null } }]);
            },
          },
          {
            visible: this.userService.getPermiso('customer', 'write') ?? false,
            label: 'Nuevo Cliente',
            icon: 'pi pi-file-plus',
            iconStyle: { 'color': 'green' },
            command: (event) => {
              this.router.navigate(['/customers/new']);
            },
          },
          {
            visible: this.userService.getPermiso('customer', 'write') ?? false,
            label: 'Contactos',
            icon: 'pi pi-fw pi-align-left',
            command: (event) => {
              this.router.navigate(['/customers/contact/new']);
            },
          },
        ],
      },
      // presupuestos
      {
        visible: this.userService.getPermiso('budget', 'read') ?? false,
        label: 'Presupuestos',
        icon: 'pi pi-folder-open',
        items: [
          {
            visible: this.userService.getPermiso('budget', 'read') ?? false,
            label: 'Listado',
            icon: 'pi pi-folder',
            command: (event) => {
              this.router.navigate(['/budgets']);
            },
          },
          {
            visible: this.userService.getPermiso('budget', 'write') ?? false,
            label: 'Nuevo',
            icon: 'pi pi-file-plus',
            iconStyle: { 'color': 'green' },
            command: (event) => {
              this.router.navigate(['/budgets/new']);
            },
          },
          {
            visible: this.userService.getPermiso('budget', 'write') ?? false,
            label: 'Materiales',
            icon: 'pi pi-file-plus',
            iconStyle: { 'color': 'green' },
            command: (event) => {
              this.router.navigate(['/materials']);
            },
          },
          {
            visible: this.userService.getPermiso('budget', 'write') ?? false,
            label: 'Nuevo Material',
            icon: 'pi pi-file-plus',
            iconStyle: { 'color': 'green' },
            command: () => {
              this.router.navigate(['/materials/new']);
            },
          },
        ],
      },
      // Albaranes
      {
        visible: this.userService.getPermiso('delivery', 'read') ?? false,
        label: 'Albaranes',
        icon: 'pi pi-book',
        items: [
          {
            visible: this.userService.getPermiso('delivery', 'read') ?? false,
            label: 'Listado',
            icon: 'pi pi-folder',
            command: () => {
              this.router.navigate(['/delivery']);
            },
          },
          {
            visible: (this.userService.getPermiso('delivery', 'write') && this.userService.getPermiso('budget', 'write')) ?? false,
            label: 'Nuevo',
            icon: 'pi pi-file-plus',
            iconStyle: { 'color': 'green' },
            command: () => {
              this.router.navigate(['/delivery/new']);
            },
          },
        ],
      },
      // Facturas
      {
        visible: this.userService.getPermiso('invoice', 'read') ?? false,
        label: 'Facturas',
        icon: 'pi pi-calculator',
        items: [
          {
            visible: this.userService.getPermiso('invoice', 'read') ?? false,
            label: 'Listado',
            icon: 'pi pi-fw pi-align-left',
            command: () => {
              this.router.navigate(['/invoices']);
            },
          },
          {
            visible: this.userService.getPermiso('invoice', 'write') ?? false,
            label: 'Nueva',
            icon: 'pi pi-file-plus',
            iconStyle: { 'color': 'green' },
            command: () => {
              this.router.navigate(['/invoices/new']);
            },
          },
          {
            visible: this.userService.getPermiso('delivery', 'read') ?? false,
            label: 'Search',
            icon: 'pi pi-fw pi-users',
            items: [
              {
                label: 'Filter',
                icon: 'pi pi-fw pi-filter',
                items: [
                  {
                    label: 'Print',
                    icon: 'pi pi-fw pi-print',
                  },
                ],
              },
              {
                icon: 'pi pi-fw pi-bars',
                label: 'List',
              },
            ],
          },
        ],
      },
      // OPCIONES
      {
        visible: this.userService.getPermiso('options', 'read') ?? false,
        label: 'opciones',
        icon: 'pi pi-fw pi-cog',
        items: [
          {
            visible: this.userService.getPermiso('address', 'write') ?? false,
            label: 'Registrar',
            icon: 'pi pi-file-edit',
            items: [
              {
                label: 'Pais',
                icon: 'pi pi-file-plus',
                iconStyle: { 'color': 'green' },
                command: () => {
                  this.router.navigate(['countries', 'new']);
                },
              },
              {
                label: 'Provincia',
                icon: 'pi pi-file-plus',
                iconStyle: { 'color': 'green' },
                command: () => {
                  this.router.navigate(['provinces', 'new']);
                },
              },
              {
                label: 'Localidad',
                icon: 'pi pi-file-plus',
                iconStyle: { 'color': 'green' },
                command: () => {
                  this.router.navigate(['/towns/new']);
                },
              },
            ],
          },
          {
            visible: this.userService.getPermiso('address', 'read') ?? false,
            label: 'Listar',
            icon: 'pi pi-folder',
            items: [
              {
                label: 'Paises',
                icon: 'pi pi-folder-open',
                command: () => {
                  this.router.navigate(['/countries']);
                },
              },
              {
                label: 'Provincias',
                icon: 'pi pi-folder-open',
                command: () => {
                  this.router.navigate(['/provinces']);
                },
              },
              {
                label: 'Localidades',
                icon: 'pi pi-folder-open',
                command: () => {
                  this.router.navigate(['/towns']);
                },
              },
            ],
          },
        ],
      },
      //INVENTARIO
      {
        visible: this.userService.getPermiso('inventario', 'read') ?? false,
        label: 'Inventario',
        icon: 'pi pi-fw pi-lightbulb',
        items: [
          {
            visible: this.userService.getPermiso('inventario', 'read') ?? false,
            label: 'scan',
            icon: 'pi pi-fw pi-qrcode',
            command: () => {
              this.router.navigate(['/scan']);
            },
          },
          {
            visible: this.userService.getPermiso('inventario', 'read') ?? false,
            label: 'Listar',
            icon: 'pi pi-fw pi-folder-open',
            command: () => {
              this.router.navigate(['/inventario/list']);
            },
          },
          {
            visible: this.userService.getPermiso('inventario', 'write') ?? false,
            label: 'Nuevo',
            icon: 'pi pi-file-plus',
            iconStyle: { color: 'green' },
            command: () => {
              this.router.navigate(['/inventario/new']);
            },
          },
          {
            visible: this.userService.getPermiso('inventario', 'read') ?? false,
            label: 'Salida',
            icon: 'pi pi-fw pi-calendar-minus',
            command: () => {
              this.router.navigate(['/inventario/out']);
            },
          },
          {
            visible: this.userService.getPermiso('inventario', 'read') ?? false,
            label: 'Entrada',
            icon: 'pi pi-fw pi-calendar-plus',
            command: () => {
              this.router.navigate(['/inventario/in']);
            },
          },
          {
            visible: this.userService.getPermiso('inventario', 'read') ?? false,
            label: 'Chekeo',
            icon: 'pi pi-verified',
            command: () => {
              this.router.navigate(['/inventario/ckeck']);
            },
          },
          {
            separator: true
          },
          {
            label: 'Proveedores',
            items: [
              {
                visible: this.userService.getPermiso('inventario', 'read') ?? false,
                label: 'Listar',
                icon: 'pi pi-folder',
                command: () => {
                  this.router.navigate(['/inventario/suppliers/list']);
                },
              },
              {
                visible: this.userService.getPermiso('inventario', 'read') ?? false,
                label: 'Nuevo',
                icon: 'pi pi-file-plus',
                iconStyle: { color: 'green' },
                command: () => {
                  this.router.navigate(['/inventario/supplier/new']);
                },
              }
            ]
          },
          {
            label: 'Familia',
            items: [
              {
                label: 'Listar',
                icon: 'pi pi-folder',
                command: () => {
                  this.router.navigate(['/inventario/family/list'])
                },
              }
            ]
          },
          {
            label: 'Marca',
            items: [
              {
                visible: this.userService.getPermiso('inventario', 'read') ?? false,
                label: 'Listar',
                icon: 'pi pi-folder',
                command: () => {
                  this.router.navigate(['/marca/list']);
                },
              },
              {
                visible: this.userService.getPermiso('inventario', 'write') ?? false,
                label: 'Nuevo',
                icon: 'pi pi-file-plus',
                iconStyle: { color: 'green' },
                command: () => {
                  this.router.navigate(['/marca/new']);
                },
              },
            ],
          },
          {
            separator: true
          },
          {
            label: 'Reparaciones',
            items: [
              {
                label: 'Listar',
                icon: 'pi pi-folder',
                command: () => { },
              },
              {
                label: 'Nuevo',
                icon: 'pi pi-file-plus',
                iconStyle: { color: 'green' },
                command: () => { },
              },
            ],
          },
        ],
      },
    ];

    this.userMenu = [
      {
        label: 'Logout',
        icon: 'pi pi-power-off',
        command: () => {
          this.userService.logout();
        },
      },
      {
        separator: true
      },
      {
        style: { 'font-size': '0.6rem' },
        label: this.userEmail!
      }
    ];
  }
}
