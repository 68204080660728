import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { jwtDecode } from "jwt-decode";
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public $loginSubjet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private access: any | null = null;
  private roll: string | null = null;
  private USA: boolean;

  constructor(private apollo: Apollo, private router: Router) {
    this.roll && this.roll === 'ADMINISTRADOR'
      ? (this.USA = true)
      : (this.USA = false);
  }

  

  isTokenExpired(token: string): boolean {
    try {
      const decoded: any = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      return decoded.exp < currentTime;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  }



  login(inputData: any): Observable<any> {
    const queryLogin = gql`
      query Login($email: String!, $pass: String!) {
        login(email: $email, pass: $pass) {
          token
          roll
          name
          email
          access {
            table {
              name
              id
            }
            read
            write
            delete
          }
        }
      }
    `;
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'no-cache',
        query: queryLogin,
        variables: {
          email: inputData.email,
          pass: inputData.pass,
        },
      })
      .valueChanges.pipe<any>(
        map((resp) => {
          this.saveLocalStorage(resp.data);
          return resp.data;
        })
      );
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  activateUser(user: string): Observable<any> {
    const mutation = gql`
      mutation ActivateUser($user: String!) {
        activateUser(user: $user) {
          ok
          message
        }
      }
    `;
    return this.apollo
      .mutate({
        fetchPolicy: 'no-cache',
        mutation,
        variables: {
          user,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp.data.activateUser;
        })
      );
  }

  getToken(): string | null {
    return localStorage.getItem('user');
  }

  saveLocalStorage(data: any) {
    if (data) {
      localStorage.setItem('user', data.login.token);
      localStorage.setItem('roll', data.login.roll);
      localStorage.setItem('name', data.login.name);
      localStorage.setItem('email', data.login.email);
      localStorage.setItem('access', JSON.stringify(data.login.access));
    }

    //const access = JSON.parse(localStorage.getItem("access")!);
  }

  passForgotten(inputData: any): Observable<any> {
    const mutattionChangePass = gql`
      mutation ForgottenPass($email: String!) {
        forgottenPass(email: $email)
      }
    `;

    return this.apollo
      .mutate<any>({
        mutation: mutattionChangePass,
        variables: {
          email: inputData.email,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  updatePass(inputData: any): Observable<any> {
    const mutattionChangePass = gql`
      mutation ChangePass($pass: String!, $tokenPass: String!) {
        changePass(pass: $pass, tokenPass: $tokenPass) {
          ok
          message
        }
      }
    `;

    return this.apollo
      .mutate<any>({
        fetchPolicy: 'no-cache',
        mutation: mutattionChangePass,
        variables: {
          pass: inputData.pass,
          tokenPass: inputData.tokenPass,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp.data.changePass;
        })
      );
  }

  checkToken(): Observable<any> {
    const query = gql`
      query Query {
        checkToken
      }
    `;

    return this.apollo
      .query({
        query: query,
      })
      .pipe(
        map((resp: any) => {
          return resp.data.checkToken;
        })
      );
  }

  newUser(data: FormGroup, permisos: FormGroup): Observable<any> {
    const accessObjet = permisos.controls['access'].value;
    const myArray: any[] = [];

    for (const clave in accessObjet) {
      const claveAnterior = clave;
      if (accessObjet[clave] != null) {
        const myObject: object = {
          table: { id: null },
          write: false,
          read: false,
          delete: false,
        };
        if (clave == claveAnterior) {
          Object.defineProperty(myObject, 'table', {
            value: { id: clave.toString() },
          });
          accessObjet[clave].forEach((element: any) => {
            switch (element.label) {
              case 'read':
                myObject['read'] = true;
                break;

              case 'write':
                myObject['write'] = true;
                break;

              case 'delete':
                myObject['delete'] = true;
                break;
            }
          });

          myArray.push(myObject);
        }
      }
    }

    const mutation = gql`
      mutation CreateUser(
        $name: String!
        $email: String!
        $pass: String!
        $roll: User_roll!
        $access: [inputAccess]!
      ) {
        createUser(
          name: $name
          email: $email
          pass: $pass
          roll: $roll
          access: $access
        ) {
          ok
          message
        }
      }
    `;

    return this.apollo
      .mutate({
        fetchPolicy: 'no-cache',
        mutation,
        variables: {
          name: data.controls['name'].value,
          email: data.controls['email'].value,
          pass: data.controls['pass'].value,
          roll: data.controls['roll'].value.name,
          access: myArray,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp.data.createUser;
        })
      );
  }

  loadUsers(skip = 0, take = 40): Observable<any> {
    const query = gql`
      query LoadUsers($take: Int!, $skip: Int!) {
        loadUsers(take: $take, skip: $skip) {
          users {
            id
            name
            email
            active
            disable
            roll
          }
          totalRecords
        }
      }
    `;
    return this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query,
        variables: {
          skip,
          take,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp.data.loadUsers;
        })
      );
  }

  loadUserById(id: string): Observable<any> {
    const query = gql`
      query LoadUserById($loadUserByIdId: String!) {
        loadUserById(id: $loadUserByIdId) {
          id
          name
          email
          roll
          active
          disable
          access {
            table {
              id
              name
            }
            read
            write
            delete
          }
        }
      }
    `;
    return this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query,
        variables: {
          loadUserByIdId: id,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp.data.loadUserById;
        })
      );
  }

  updateUser(
    id: string,
    form: FormGroup,
    permisos: FormGroup
  ): Observable<any> {
    const accessObjet = permisos.controls['access'].value;
    const myArray: any[] = [];
    const roll = form.get('roll')?.value;

    for (const clave in accessObjet) {
      const claveAnterior = clave;
      if (accessObjet[clave] != null) {
        const myObject: object = {
          table: { id: null },
          write: false,
          read: false,
          delete: false,
        };
        if (clave == claveAnterior) {
          Object.defineProperty(myObject, 'table', {
            value: { id: clave.toString() },
          });
          accessObjet[clave].forEach((element: any) => {
            switch (element.label) {
              case 'read':
                myObject['read'] = true;
                break;

              case 'write':
                myObject['write'] = true;
                break;

              case 'delete':
                myObject['delete'] = true;
                break;
            }
          });

          myArray.push(myObject);
        }
      }
    }

    const data = {
      id: id,
      name: form.get('name')?.value || undefined,
      email: form.get('email')?.value || undefined,
      roll: roll.name,
      active: form.get('active')?.value,
      disable: form.get('disable')?.value,
      access: myArray,
    };

    const mutation = gql`
      mutation UpdateUser($data: inputUser!) {
        updateUser(data: $data) {
          ok
          message
        }
      }
    `;
    return this.apollo
      .mutate({
        fetchPolicy: 'no-cache',
        mutation,
        variables: {
          data,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp.data.updateUser;
        })
      );
  }

  getControl(): Observable<any> {
    const query = gql`
      query GetControl {
        getControl {
          control {
            id
            name
          }
          totalRecords
        }
      }
    `;

    return this.apollo
      .query({
        query,
      })
      .pipe(
        map((resp: any) => {
          return resp.data.getControl.control;
        })
      );
  }

  getPermiso(tabla: string | undefined, tipo: string): boolean {
    this.access = JSON.parse(localStorage.getItem('access')!);
    this.roll = localStorage.getItem('roll');
    this.roll && this.roll === 'ADMINISTRADOR'
      ? (this.USA = true)
      : (this.USA = false);

    let resul = true;
    if (tabla == undefined && this.USA == false) {
      return false;
    }

    if (this.USA == true) return true;

    this.access.some((valor: any) => {
      if (
        tabla != undefined &&
        valor.table['name'] == tabla &&
        valor[tipo] == false
      ) {
        resul = false;
      }
    });

    return resul;
  }

  deleteUser(id: string): Observable<any> {
    const mutation = gql`
      mutation DeleteUser($deleteUserId: String!) {
        deleteUser(id: $deleteUserId) {
          ok
          message
        }
      }
    `;

    return this.apollo
      .mutate({
        fetchPolicy: 'no-cache',
        mutation,
        variables: {
          deleteUserId: id,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp.data.deleteUser;
        })
      );
  }

  disableUser(id: string): Observable<any> {
    const mutation = gql`
      mutation DisableUser($disableUserId: String!) {
        disableUser(id: $disableUserId) {
          ok
          message
        }
      }
    `;

    return this.apollo
      .mutate({
        fetchPolicy: 'no-cache',
        mutation,
        variables: {
          disableUserId: id,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp.data.disableUser;
        })
      );
  }

  sendMailActivated(email: string): Observable<any> {
    const mutation = gql`
      mutation DisableUser($email: String!) {
        sendMailActivate(email: $email) {
          ok
          message
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          email,
        },
      })
      .pipe(
        map((resp: any) => {
          return resp.data.sendMailActivate;
        })
      );
  }
}
