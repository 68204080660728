import { AfterContentInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuItem, MessageService } from 'primeng/api';
import { Icountry } from '../country.model';
import { CountryService } from '../../../services/country/country.service';
import { ActivatedRoute } from '@angular/router';
import { ComunService } from '../../../services/comun/comun.service';
import { SharedService } from '../../../services/shared/shared.service';

@Component({
  selector: 'app-new-country',
  templateUrl: './new-country.component.html',
  styleUrls: ['./new-country.component.scss'],
  providers:[MessageService]

})
export class newCountryComponent  {

  // variables
  loading = false;
  formData: FormGroup;
 
  constructor( private fb: FormBuilder, 
               private countryService: CountryService,
               private messageService: MessageService,
               private activateRouter: ActivatedRoute,
               private comunservice: ComunService,
              private shareService:SharedService
               ) {

    this.formData = this.fb.group( {
      name: [null,[Validators.required, Validators.minLength(4)]],
      id: undefined
    });

   // comprobar edicción
   const misDatos = this.activateRouter.queryParams;
   misDatos.subscribe((params: any) => {
     if (params.id == undefined) {
      
      // breakcrund
      this.setMenu([ { label: "LISTADO PAISES", routerLink: ["/countries"] },{ label: "NUEVO PAIS" }])
      return;
     } 

   
     this.setMenu([ { label: "LISTADO PAISES", routerLink: ["/countries"] },{ label: "ACTUALIZAR PAIS" }])
     this.formData.setValue({id: params.id, name:params.name});
     
     
   });

   }
   
 
;


   btnNew(): void {
    
    this.formData.reset();
    this.setMenu([
      { label: "LISTADO PAISES", routerLink: ["/countries"] },
      { label: "CREAR PAIS" },
    ])

  }

  save():void {
   
    
    const data: Icountry = {
      name: this.formData.value.name
    }

    this.loading= true;
    this.countryService.setCountry(data).subscribe({

      next: ((resp:any ) => {

        this.messageService.add({
          key:      'msg',
          severity: 'success',
          summary:  'MDG Info',
          detail:   'Pais creado correctamente'
        })
        this.loading= false;
        console.log(resp);
        
        this.formData.setValue({id:resp, name: this.formData.get('name')?.value});


      }),
      error: (err => {
        if (err.message.includes('Unique')) {
          err.message = 'El pais ya existe'
        }
        this.messageService.add({
          key:      'msg',
          severity: 'error',
          summary:  'MDG Info',
          detail:   err.message
        })
        this.loading= false;

      })
    }) 
    
  }

  update(){
    const data: Icountry = {
      id: this.formData.value.id,
      name: this.formData.value.name
    }
    this.loading= true;
    this.countryService.updateCountry(data).subscribe({
      next: (resp => {
        this.messageService.add({
          key:      'msg',
          severity: 'success',
          summary:  'MDG Info',
          detail:   'Pais Actualizado Correctamente'
        })
        this.loading= false;
  
      }),
      error: (err => {
        this.messageService.add({
          key:      'msg',
          severity: 'error',
          summary:  'MDG Info',
          detail:   err.error.message
        })
        this.loading= false;
  
      })
    }) 
    
  }

  setMenu(menu: MenuItem []) {
    this.comunservice.setTitleBar(menu);

  }
}
