import { AfterContentInit, AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Icountry } from '../../country/country.model';
import { Iprovince } from '../../province/province.model';
import { ProvinceService } from '../../../services/provinces/province.service';
import { CountryService } from '../../../services/country/country.service';
import { MenuItem, MessageService } from 'primeng/api';
import { ComunService } from 'src/app/services/comun/comun.service';
import { Town, Itown } from '../town.model';
import { TownService } from '../../../services/town/town.service';
import { ActivatedRoute } from '@angular/router';
import { Dropdown } from 'primeng/dropdown';
import { FormBuilder, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-new',
  templateUrl: './new-town.component.html',
  styleUrls: ['./new-town.component.scss'],
  providers:[MessageService]
})
export class NewTownComponent {
  @ViewChild('PaisInput')
  pais!: Dropdown;

  countries: Icountry [] | undefined = [];
  provinces: Iprovince[] | undefined = [];
  selectedCountry:Icountry | undefined = undefined;
  selectedProvince:Icountry | undefined = undefined;
  town = new Town();
  inputName:string | undefined = undefined;
  loading = false;
 

  constructor( 
    private provinceService: ProvinceService,
    private countryService: CountryService,
    private townService: TownService,
    private messageService: MessageService,
    private comunservice: ComunService,
    private activatedRoute:ActivatedRoute,
    private fb: FormBuilder

    ) {
    this.comunservice.setTitleBar([
      { label: "LISTADO LOCALIDADES", routerLink: ["/towns"] },
      { label: "CREAR LOCALIDAD" },
    ]);

     // CHECK EDIT
    const {id} = this.activatedRoute.snapshot.queryParams;
    
    if (id != undefined) {
      this.loadEdit(id);
    }else {
      this.loadCountries();
    }
    
  }


  btnSave(update = false):void {
  
    try {
      this.town.data!.errors = [];
      this.town.setName= this.inputName;
      this.town.setCountry= this.selectedCountry?.id;
      this.town.setProvince= this.selectedProvince?.id;
 
    } catch (err) {
      console.log(err);
      
    }
    
    if (this.town.data?.errors.length ==0) {
     
      update ? this.update(): this.save();
     
    }else {
      this.showErrors();

    }

   
 
  }

  loadCountries(): Promise<boolean> {

    return new Promise ((resolve, reject)=> {
      this.countryService.getCountries(10000,0).subscribe({
        next:(data) => {
          console.log(data);
          
          this.countries = data.countries;
          resolve (true);
        },
        error:(err) => {
          console.error(err);
          reject (false);
          
        }
      })

    });


  }

  changeCountry(): Promise<boolean>{

    return new Promise ((resolve, reject)=> {
    
      this.provinces=[];
      if ( !this.selectedCountry) return;
      this.provinceService.getProvincesByCountry(this.selectedCountry)
      .subscribe({
        next: (data) => {
          this.provinces = data.provinces
          resolve (true);
        },
        error: (err) => {
          console.error(err);
          reject(false)
        }
      })
   })
       
  }

  showErrors() {
    // CREAR MI LISTA DE ERRORES
    let fieldError = "";
  
    this.town.data?.errors!.forEach(error => {
        fieldError += error.message + '\<br>';
        
    });
  
    
      setTimeout(() => {
        this.messageService.add({
          key: "errorForm",
          severity: "error",
          summary: "MDG Audiovisuales",
          detail: fieldError
         
        });
        fieldError='';
      }, 0);
  
  }

  save():void {
    
    const data: Itown = {
      name: this.town.data?.name,
      countryId: this.town.data?.countryId,
      provinceId: this.town.data?.provinceId
    }
  
     this.loading= true;

     this.townService.createTown(data).subscribe({
      next: (resp) => {
        this.messageService.add({
          key:      'msg',
          severity: 'success',
          summary:  'MDG Info',
          detail:   resp.createTown
        })
        this.loading= false;
      },
      error:(err) => {
        this.messageService.add({
          key:      'msg',
          severity: 'error',
          summary:  'MDG Info',
          detail:   err.message
        })
        this.loading= false;
      }
     })
  }

  update():void {
    this.townService.updateTown(this.town.data!).subscribe({
      next: (resp) => {
        this.messageService.add({
          key: 'msg',
          severity: 'success',
          summary: 'MDG Info',
          detail: 'Localidad Modificada correctamente'
        })
      },
      error: (err) => {
        console.log(err);
        this.messageService.add({
          key: 'msg',
          severity: 'error',
          summary: 'MDG Info',
          detail: 'Se ha producido un error'
        })
      }
    })
    
  }

  btnNew(): void {
    this.selectedCountry= undefined;
    this.selectedProvince= undefined;
    this.town.data!.id = undefined;
    this.town.data!.name = undefined;
    this.town.data!.countryId = undefined;
    this.town.data!.provinceId = undefined;
    this.inputName='';
    this.setMenu([
      { label: "LISTADO LOCALIDADES", routerLink: ["/towns"] },
      { label: "CREAR LOCALIDAD" },
    ])

  }

  async loadEdit(id:string) {
    await this.loadCountries();
     this.townService.getTownById(id).subscribe({
      next: async (resp) => {
        try {
          this.selectedCountry= {id: resp.towns[0].country.id, name: resp.towns[0].country.name };
          this.town.setCountry = this.selectedCountry.name;
          await this.changeCountry();
          this.selectedProvince= {id: resp.towns[0].province.id, name: resp.towns[0].province.name };
          this.town.setProvince= this.selectedProvince.name;
          this.inputName = resp.towns[0].name;
          this.town.setName = this.inputName;
          this.town.data!.id = id;

          this.comunservice.setTitleBar( [
            { label: "LISTADO LOCALIDADES", routerLink: ["/towns"] },
            { label: "ACTUALIZAR LOCALIDAD" },
          ]);
        } catch (err) {
          console.log(err);
        }
       
      },
      error: (err)=> {
        console.log(err);
        
      }
    })
    
   
   
    
    
  }

  setMenu(menu: MenuItem []): void {
    this.comunservice.setTitleBar(menu);
  }
  

}
